.state-card:hover {
  cursor: pointer;
  box-shadow: 0 0 20px rgb(26, 30, 39); /* s
    
}
.state-card{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* set initial box-shadow */
  transition: box-shadow 0.2s ease-in-out;
}
.selected {
  background-color: yellow;
  box-shadow: 0 0 10px yellow;
}
