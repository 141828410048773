.login-btn {
  color: white !important;
  background-color: rgb(26, 67, 121) !important;
  border: 1px solid rgb(26, 67, 121) !important;
  transition: all 0.3s ease !important;
}

.login-btn:hover {
  color: white !important;
  background-color: rgb(19, 52, 92) !important;
  border: 1px solid rgb(19, 52, 92) !important;
}
