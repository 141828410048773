.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #6b7989;
  transform: translate(-50%, -50%);
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

.position-under {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #6b7989;
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

.center {
  text-align: center;
}

.highlight-dark-blue {
  color: rgb(26, 67, 121);
}
