.react-datepicker__day--custom-class {
  background-color: #14213d;
  color: white;
  border-radius: 50%;
  padding: 0.25rem;
}

.states-overflow {
  max-height: 875px;
  overflow-y: auto;
}

.table-min-height {
  min-height: 650px;
}

.theme-bg-color {
  background: #1c2a45;
}

.download-pointer:hover {
  cursor: pointer;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulsate {
  animation: pulsate 1s ease-in-out infinite;
}
