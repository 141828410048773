.react-datepicker__header {
    background-color: rgb(26, 67, 121);
  }

  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__current-month {
    color: white;
  }

